import vars from './vars';

export default {
	open(id) {
		let $modal = vars.$modal.filter(`[data-modal="${id}"]`);

		$modal.removeClass('is-visually-hidden');
		$modal.animate({opacity: 1}, 500, () => {
			console.log('opened');
		});
	},

	close() {
		let $modal = vars.$modal.not('.is-visually-hidden');

		$modal.animate({opacity: 0}, 500, () => {
			$modal.addClass('is-visually-hidden');
			console.log('closed');
		});
	},

	init() {
		vars.$modalClose.on('click', (event) => {
			event.stopPropagation();
			let $target = $(event.target);

			if ($target.data('close') === true) {
				vars.$body.removeClass('is-modal-opened');
				this.close();
			}
		});

		vars.$modalOpen.on('click', (event) => {
			event.preventDefault();
			this.close();
			let id = $(event.currentTarget).data('modal');

			vars.$body.addClass('is-modal-opened');
			this.open(id);
		});

		vars.$window.on('keydown', (event) => {
			if (event.keyCode === vars.KEY_ESC) {
				vars.$body.removeClass('is-modal-opened');
				this.close();
			}
		});
	},
};
