import vars, {mobile} from './vars';

// HAMBURGER MENU
vars.$hamburger.on('click', () => {
	vars.$hamburger.toggleClass('is-active');
	vars.$mobileMenu.toggleClass('is-open');
});

// Main Menu Links with Hash
if (vars.$navLink.length) {
	vars.$navLink.add(vars.$mobileMenuLink).add(vars.$link).on('click', (event) => {
		event.preventDefault();
		let that = event.currentTarget;

		console.log(that);

		// toggle active state for current link
		vars.$navLink.add(vars.$mobileMenuLink).add(vars.$link).removeClass('is-active');
		$(that).addClass('is-active');

		if (/#/.test(that.href) && location.pathname === that.pathname) {
			event.preventDefault();

			if (mobile) {
				vars.$hamburger.removeClass('is-active');
				vars.$mobileMenu.removeClass('is-open');
			}

			let url = $(that).attr('href');
			let hash = url.split('#')[1];
			let id = `#${hash}`;
			let top = $(id).offset().top;

			vars.$bodyHtml.animate({scrollTop: top}, 500, 'swing');
		} else {
			location.href = that.href;
		}
	});
}

// Page load - anchor navigation
if (location.hash) {
	vars.$window.on('load', () => {
		let id = location.hash;
		let top = $(id).offset().top;

		vars.$bodyHtml.animate({scrollTop: top}, 500, 'swing');
	});
}
