import Parser from 'ninelines-ua-parser';

const parser = new Parser();
let mobile = false;

if (parser.getDevice().type === 'mobile' || parser.getDevice().type === 'tablet' || innerWidth <= 768) {
	mobile = true;
}

export {mobile};

export default {
	$document: $(document),
	$window: $(window),
	$body: $('body'),
	$bodyHtml: $('body, html'),
	$header: $('.header'),

	$nav: $('.nav'),
	$navItem: $('.nav__item'),
	$navLink: $('.nav__link'),
	$mobileMenuLink: $('.mobile-menu__link'),
	$link: $('.js-link'),
	$up: $('.js-up'),

	$search: $('.js-search'),
	$searchBlock: $('.js-search-block'),
	$searchToggler: $('.js-search-toggler'),
	$searchInput: $('.js-search-input'),
	$searchSubmit: $('.js-search-submit'),

	$hamburger: $('.js-hamburger'),
	$mobileMenu: $('.js-mobile-menu'),

	$hero: $('.js-hero'),
	$heroMore: $('.js-hero-more'),

	$sectionReason: $('.js-section-reason'),

	$modal: $('.js-modal'),
	$modalInner: $('.js-modal-inner'),
	$modalClose: $('.js-modal-close'),
	$modalOpen: $('.js-modal-open'),
};
