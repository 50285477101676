import {TweenMax, TimelineMax} from 'gsap';
import vars, {mobile} from './vars';

let tl1 = new TimelineMax({ repeat: -1 });

tl1
	.to($('image.step--1'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--2'), 0.45, {
		autoAlpha: 1,
		delay: 0.3
	})
	.to($('image.step--1'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--3'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--2'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--4'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--3'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--5'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--4'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--6'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--5'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--7'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--6'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--8'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--7'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--8'), 0.3, {
		autoAlpha: 0,
		delay: 0.3
	});

let tl2 = new TimelineMax({ repeat: -1 });

tl2
	.to($('image.step--1-1'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--1-2'), 0.45, {
		autoAlpha: 1,
		delay: 0.3
	})
	.to($('image.step--1-1'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--1-3'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--1-2'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--1-4'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--1-3'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--1-4'), 0.3, {
		autoAlpha: 0,
		delay: 0.3
	})

// Trace 2
let tl21 = new TimelineMax({ repeat: -1 });

tl21
	.to($('image.step--2-1'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--2-2'), 0.45, {
		autoAlpha: 1,
		delay: 0.3
	})
	.to($('image.step--2-1'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--2-3'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--2-2'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--2-4'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--2-3'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--2-5'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--2-4'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--2-6'), 0.45, {
		autoAlpha: 1,
	})
	.to($('image.step--2-5'), 0.3, {
		autoAlpha: 0,
	})
	.to($('image.step--2-6'), 0.3, {
		autoAlpha: 0,
		delay: 0.3
	});


// Footprints
// let pawLeft = new TimelineMax({
// 	repeat: -1,
// });
// pawLeft.to($('.hero__footprints__left'), 0.7, {
// 	css: {
// 		bottom: '100%',
// 		opacity: 0,
// 		scale: 1.3,
// 	},
// });

// let pawRight = new TimelineMax({
// 	repeat: -1,
// });
// pawRight.to($('.hero__footprints__right'), 0.7, {
// 	css: {
// 		bottom: '100%',
// 		opacity: 0,
// 		scale: 1.3,
// 	},
// });