import vars, {mobile} from './vars';

// eslint-disable-next-line consistent-return
(() => {
	if (mobile) {
		return false;
	}

	let limit = innerHeight / 3;

	vars.$window.scroll(() => {
		if (vars.$window.scrollTop() > limit) {
			vars.$up.fadeIn();
		} else {
			vars.$up.fadeOut();
		}
	});

	// scroll body to 0px on click
	vars.$up.click(() => {
		vars.$bodyHtml.animate({scrollTop: 0}, 500, 'swing');

		return false;
	});
})();
