import vars from './vars';

vars.$search.submit((event) => {
	event.preventDefault();

	if (vars.$searchSubmit.hasClass('is-active')) {
		vars.$document.trigger('clear-search-result');
	} else {
		vars.$searchSubmit.addClass('is-active');
	}

	let query = vars.$searchInput.val().trim();

	// if empty query - stop function
	if (!query.length) {
		return false;
	}

	// scroll to the first search result
	let position = $(`.section *:contains("${query}")`).length ? $(`.section *:contains("${query}")`).first().offset().top : vars.$window.scrollTop();

	vars.$bodyHtml.animate({scrollTop: position}, 500, 'swing');

	// unwrap all old serach results
	$('.search__result').each((i, element) => {
		$(element).contents().unwrap();
	});

	// add wrapper for each search result
	$(`.section *:contains("${query}")`).each((i, element) => {
		if ($(element).children().length < 1) {
			$(element).html(
				$(element).text().replace(
					query, `<span class="search__result">${query}</span>`
				)
			);
		}
	});
});

vars.$searchToggler.on('click', (event) => {
	if ($(event.currentTarget).hasClass('is-active')) {
		vars.$document.trigger('search-close');
	} else {
		vars.$document.trigger('search-open');
	}
});

vars.$document.on('search-open', () => {
	vars.$searchToggler.addClass('is-active');
	vars.$searchBlock.addClass('is-open');
});

vars.$document.on('search-close', () => {
	vars.$searchToggler.removeClass('is-active');
	vars.$searchBlock.removeClass('is-open');
});

vars.$document.on('clear-search-result', () => {
	vars.$searchSubmit.removeClass('is-active');
	vars.$searchInput.val('');
	// unwrap all old serach results
	$('.search__result').each((i, element) => {
		$(element).contents().unwrap();
	});
});
