import vars, {mobile} from './vars';

(() => {
	if (mobile) {
		return false;
	}

	const headerHeight = vars.$header.height();
	let lastId;
	let scrollItems = vars.$navLink.map((i, link) => {
		let item = $($(link)[0].hash);

		if (item.length) {
			return item;
		}
	});

	// Bind to scroll
	vars.$window.scroll(() => {
		// Get container scroll position
		let fromTop = vars.$window.scrollTop() + headerHeight;

		// Get id of current scroll item
		let cur = scrollItems.map((i, item) => {
			if ($(item).offset().top < fromTop) {
				return $(item).attr('id');
			}
		});

		// if we scroll not deep enough
		if (!cur.length) {
			lastId = null;
			vars.$navLink.removeClass('is-active');

			return false;
		}

		// Get the id of the current element
		let id = cur[cur.length - 1];

		if (lastId !== id) {
			lastId = id;

			// Set/remove active class
			vars.$navLink.removeClass('is-active');
			vars.$navLink.filter(`[href*="#${id}"]`).addClass('is-active');
		}
	});
})();
