import vars from './vars';
import modal from './modal';

// MODAL
modal.init();

vars.$heroMore.on('click', () => {
	let position = vars.$sectionReason.offset().top;

	vars.$bodyHtml.animate({scrollTop: position}, 500, 'swing');
});

